import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';

import { CookiesProvider } from 'react-cookie';
import { theme } from 'styled/theme';

import type { FC, ReactNode } from 'react';

// default is our production app-id
const intercomAppId = process.env.INTERCOM_APP_ID || 'tv6jsyee';

/** These are providers, expected to used in `gatsby-browser` and `gatsby-ssr` to wrap all of Gatsby. */
export const Providers: FC<{ children: ReactNode }> = ({ children }) => (
  <CookiesProvider>
    <IntercomProvider appId={intercomAppId}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </IntercomProvider>
  </CookiesProvider>
);
