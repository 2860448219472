import { lighten } from 'polished';
import { createStyledBreakpointsTheme } from 'styled-breakpoints';
import { tokens } from './variables/tokens';
import type { Theme } from './types';

const styledBreakpointTheme = createStyledBreakpointsTheme();

export const theme: Theme = {
  baseText: {
    fontSize: tokens.typography.fontSizes.sm,
    lineHeight: tokens.typography.lineHeights.sm,
  },

  /**
   * Currently used for buttons, and pricing cards.
   */
  borderRadius: '0.5rem',
  borderWidth: '0.2rem',
  // TODO: update other places that use values to pull from here e.g. pricing
  fontSize: {
    xxxs: tokens.typography.fontSizes.xxxs,
    xxs: tokens.typography.fontSizes.xxs,
    xs: tokens.typography.fontSizes.xs,
    sm: tokens.typography.fontSizes.sm,
    md: tokens.typography.fontSizes.md,
    lg: tokens.typography.fontSizes.lg,
    xl: tokens.typography.fontSizes.xl,
    xxl: tokens.typography.fontSizes.xxl,
    xxxl: tokens.typography.fontSizes.xxxl,
  },

  lineHeight: {
    xxxs: tokens.typography.lineHeights.xxxs,
    xxs: tokens.typography.lineHeights.xxs,
    xs: tokens.typography.lineHeights.xxs,
    sm: tokens.typography.lineHeights.sm,
    md: tokens.typography.lineHeights.md,
    lg: tokens.typography.lineHeights.lg,
    xl: tokens.typography.lineHeights.xl,
    xxl: tokens.typography.lineHeights.xxl,
    xxxl: tokens.typography.lineHeights.xxxl,
  },

  fontWeight: {
    bold: tokens.typography.fontWeights.bold,
    semiBold: tokens.typography.fontWeights.semiBold,
    normal: tokens.typography.fontWeights.normal,
  },

  fontFamily: {
    // This is used primarily for headings
    financier: tokens.typography.fontFamily.financier,
    // This is used primarily for body text
    nunitoSans: tokens.typography.fontFamily.nunitoSans,
  },

  /**
   * Currently used for tooltips, and pricing cards.
   */
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

  maxSiteWidth: '1200px',

  dimensions: {
    size0: 0,
    size1: tokens.baseSize * 1, // 4px - paddingXs
    size2: tokens.baseSize * 2, // 8px - paddingSm
    size3: tokens.baseSize * 3, // 12px
    size4: tokens.baseSize * 4, // 16px - gutter/padding
    size5: tokens.baseSize * 5, // 20px
    size6: tokens.baseSize * 6, // 24px - paddingMd
    size7: tokens.baseSize * 7, // 28px
    size8: tokens.baseSize * 8, // 32px - paddingLg
    size9: tokens.baseSize * 10, // 40px
    size10: tokens.baseSize * 12, // 48px - paddingXl
    size11: tokens.baseSize * 14, // 56px
    size12: tokens.baseSize * 15, // 60px
    size13: tokens.baseSize * 18, // 72px
    size14: tokens.baseSize * 20, // 80px
    size15: tokens.baseSize * 23, // 92px - paddingXxl
  },

  components: {
    button: {
      // TODO: Our fontSize on the button does not match the design system.
      padding: '1.5rem',
      height: '4rem',
    },
    header: {
      logoVerticalPadding: '1.5rem',
      height: '7rem', // button.height + button.padding*2
    },
    list: {
      leftMargin: {
        ul: '2.5rem',
        ol: '3rem',
      },
      bulletPaddingOffset: '5px',
    },
    socialLink: {
      size: {
        sm: '30px',
        xs: '20px',
      },
    },
    table: {
      /**
       * Top & bottom padding for table cells
       */
      headerPadding: '1.8rem',
      rowPadding: '1rem',
    },
  },

  colors: {
    black: tokens.colors.black,
    grey: tokens.colors.mushroom50,
    yellow: tokens.colors.lemon55,
    primary: tokens.colors.blueberry40,
    secondary: tokens.colors.tea, // background colour for secondary buttons
    transparent: 'transparent',
    white: tokens.colors.white,
    cream: tokens.colors.cream,
    orange: lighten(0.05, tokens.colors.carrot50),
    navyBlue: tokens.colors.fig10,
    blue: lighten(0.1, tokens.colors.fig10),
    callout: lighten(0.45, tokens.colors.carrot50),
    orangeHighlight: lighten(0.4, tokens.colors.carrot50),
    primaryHighlight: lighten(0.5, tokens.colors.blueberry40),
    yellowHighlight: lighten(0.35, tokens.colors.lemon55),
    navyBlueHighlight: lighten(0.8, tokens.colors.fig10),
  },
  ...styledBreakpointTheme,
};
